<template>
  <Editor
    v-model="value"
    api-key="no-api-key"
    :init="initData"
  />
</template>

<script>
/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.min.css';

/* Import plugins */
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';

/* content UI CSS is required */
import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.min.css';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from 'tinymce/skins/content/default/content.min.css';

import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      initData: {
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
        plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion autoresize',
        height: 600,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image table',
        max_height: 1000,
        image_class_list: [
          { title: 'Zvacsenie', value: 'img-zoom' },
        ],
        setup: function (editor) {
          // editor.on('BeforeSetContent', function (e) {
          //   //Look at this object - it tells you a bunch of stuff about the insert activity
          //   //such as was it via paste?  Was there selected content in TinyMCE that will be overwritten?  
          //   //Using the browser tooling you can inspect the e object and learn a lot about the content that will be set.
          //   console.log(e.content);

          //   //Then you can modify that content if you want...for example:
          //   if (e.content.startsWith("<img")) {
          //     console.log('ADDING CONTENT');
          //     e.content = '<div class="container"><input type="checkbox" id="zoomCheck"><label for="zoomCheck">' + e.content + '</label></div>';
          //   }
          // });
        }
      }
    }
  },

  mounted() {
  },

  watch: {
    value(currentValue) {
      this.$emit('input', currentValue);
    }
  },

  beforeUnmount() {
  },

  methods: {
    handleImageClick(event) {
      const imageElement = event.target;
      // Implement your custom logic for image overlay here
      // For example, you can display a modal with more options.
      console.log('Image clicked:', imageElement.src);
    },
  }
}
</script>

<style scoped>
.logo {
  display: block;
  margin: 0 auto 2rem;
}

@media (min-width: 1024px) {
  #sample {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 1000px;
  }
}
</style>
